import { G, Svg, Polygon } from './svg';

export default function DropDownIcon() {
  return (
    <Svg height="100%" width="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
      <G fill="currentColor">
        <Polygon fill="currentColor" points="12 1 18 9 6 9 12 1" />
        <Polygon points="12 23 18 15 6 15 12 23" />
      </G>
    </Svg>
  );
}
